<template>
  <div>
      <video width="320" height="240" controls>
  <source :src="welcomeVideo" type="video/mp4">
  <source :src="welcomeVideo" type="video/ogg">
Your browser does not support the video tag.
</video>
  </div>
</template>

<script>
export default {
data() {
    return {
        welcomeVideo: "https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/welcome.mov?alt=media&token=80019ac7-dfc0-45c7-933a-b1c353de3f06"
    }
},
}
</script>

<style>

</style>